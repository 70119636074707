// Core
import React, { useState } from "react"
import emailjs from "emailjs-com"
import { Formik, Form, Field, ErrorMessage } from "formik"
import clsx from "clsx"
import * as Yup from "yup"

// Layout
import { Layout } from "../layouts"

// Components
import { Wrapper, Seo, Loader, Alert } from "../components"

// Icons
import { BsArrowRightShort } from "react-icons/bs"
import EnvelopeIcon from "../assets/icons/envelope.inline.svg"

const initialValues = {
  name: "",
  email: "",
  phone: "",
  description: "",
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
})

const ContactUsPage = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  const onSubmit = (values, onSubmitProps) => {
    setError(null)
    setResponse(null)
    setLoading(true)
    emailjs
      .send(
        "outloud_service_id",
        "outloud_template_contact",
        values,
        "user_qwIlbUBCHKpltEwZ4p33b"
      )
      .then(
        function (response) {
          setResponse(response)
          setLoading(false)
        },
        function (error) {
          setLoading(false)
          setError(error)
        }
      )
    onSubmitProps.resetForm()
  }

  return (
    <Layout>
      <Wrapper>
        <Seo title="Contact Us" />

        <div className="contact__row">
          <section className="contact-form">
            <div className="contact-form__description">
              <span className="contact-form__subtitle">Contact us</span>
              <h2 className="contact-form__title">
                Let`s chat
                <span role="img" aria-label="chat-icon">
                  👋
                </span>
              </h2>
            </div>

            {response?.status && (
              <Alert
                type="success"
                text="Your message has been successfully sent. We will contact you soon!"
              />
            )}
            {error && (
              <Alert
                type="error"
                text="Error occured while sending message. Please try again later."
              />
            )}

            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {props => (
                <Form>
                  <div className="form-group">
                    <label className="form-group__label" htmlFor="name">
                      Name of business
                    </label>
                    <Field
                      className={clsx(
                        "form-group__field",
                        props.touched.name && props.errors.name
                          ? "form-group__field--error"
                          : ""
                      )}
                      id="name"
                      type="text"
                      name="name"
                      placeholder="Type your name of business"
                    />
                    <span className="form-group__error">
                      <ErrorMessage name="name" />
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-group__label" htmlFor="email">
                      Contact email
                    </label>
                    <Field
                      className={clsx(
                        "form-group__field",
                        props.touched.email && props.errors.email
                          ? "form-group__field--error"
                          : ""
                      )}
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Type your contact email"
                    />
                    <span className="form-group__error">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-group__label" htmlFor="phone">
                      Contact phone
                    </label>
                    <Field
                      className="form-group__field"
                      id="phone"
                      type="tel"
                      name="phone"
                      placeholder="Type your contact phone"
                    />
                    <span className="form-group__error">
                      <ErrorMessage name="phone" />
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-group__label" htmlFor="description">
                      Description
                    </label>
                    <Field
                      className="form-group__field form-group__textarea"
                      as="textarea"
                      id="description"
                      type="text"
                      name="description"
                      placeholder="Type description"
                    />
                  </div>

                  <button
                    className="contact-form__button"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        Submit
                        <BsArrowRightShort className="contact-form__button--arrow" />
                      </>
                    )}
                  </button>
                </Form>
              )}
            </Formik>
          </section>

          <section className="contact-office">
            <span className="contact-office__title">Our Office</span>
            <h2 className="contact-office__city">San Francisco</h2>

            <address className="contact-office__address">
              <span className="contact-office__address--item">
                2261 Market Street #4396,
              </span>
              <span className="contact-office__address--item">
                San Francisco, CA 94114,
              </span>
              <span className="contact-office__address--item">
                United States
              </span>
            </address>

            <div className="contact-office__info">
              <h3 className="contact-office__info--title">
                You can contact us at:
              </h3>
              <div className="contact-office__info--row">
                <EnvelopeIcon className="contact-office__info--icon" />
                <a
                  href="mailto:team@outloud.ai"
                  className="contact-office__info--link"
                >
                  team@outloud.ai
                </a>
              </div>
            </div>
          </section>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default ContactUsPage
